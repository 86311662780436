import { BeneficiaryDto, CurrencyBeneficiaryDto } from "./beneficiary-dto";

export const initialBeneficiaryValues: BeneficiaryDto = {
  firstname: "",
  lastname: "",
  entityType: "business",
  currency: "GBP",
  address: "",
  city: "",
  postcode: "",
  state: "",
  country: "IM",
  bankCountry: "IM",
  accountNumber: "",
  sortCode: "",
  IBAN: "",
  bicSwift: "",
  companyName: "",
};

export const initialCurrencyBeneficiaryValues: CurrencyBeneficiaryDto = {
    id: "",
    bank_account_holder_name: "",
    name: "",
    email: "",
    payment_types: [""],
    beneficiary_address: "",
    beneficiary_country: "",
    beneficiary_entity_type: "",
    beneficiary_company_name: "",
    beneficiary_first_name: "",
    beneficiary_last_name: "",
    beneficiary_city: "",
    beneficiary_postcode: "",
    beneficiary_state_or_province: "",
    beneficiary_date_of_birth: "",
    beneficiary_identification_type: "",
    beneficiary_identification_value: "",
    bank_country: "",
    bank_name: "",
    bank_account_type: "",
    currency: "",
    account_number: "",
    routing_code_type_1: "",
    routing_code_value_1: "",
    routing_code_type_2: "",
    routing_code_value_2: "",
    bic_swift: "",
    iban: "",
    default_beneficiary: "",
    creator_contact_id: "",
    bank_address: "",
    beneficiary_external_reference: ""
};
