import * as yup from "yup";

export const accountSchema = yup.object({
  id: yup.string().required(),
  account_name: yup.string().required("Please enter the Account Name"),
  brand: yup.string(),
  your_reference: yup.string(),
  status: yup.string(),
  street: yup.string(),
  city: yup.string(),
  state_or_province: yup.string(),
  country: yup.string(),
  postal_code: yup.string(),
  spread_table: yup.string(),
  legal_entity_type: yup.string(),
  identification_type: yup.string(),
  identification_value: yup.string(),
  short_reference: yup.string(),
  api_trading: yup.string(),
  online_trading: yup.string(),
  phone_trading: yup.string(),
  process_third_party_funds: yup.string(),
  settlement_type: yup.string(),
  agent_or_reliance: yup.string(),
  terms_and_conditions_accepted: yup.string(),
  bank_account_verified: yup.string(),
  cloudCurrencyId: yup.string(),
  openPaydId: yup.string(),
  isApproved: yup.boolean(),
});
export const contactSchema = yup.object({
  login_id: yup.string(),
  id: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  account_id: yup.string(),
  account_name: yup.string(),
  status: yup.string(),
  locale: yup.string(),
  timezone: yup.string(),
  email_address: yup.string(),
  mobile_phone_number: yup.string(),
  phone_number: yup.string(),
  your_reference: yup.string(),
  date_of_birth: yup.string(),
  account: accountSchema.optional().nullable(true),
  complyLaunchId: yup.string(),
})

export const settleAccountSchema = yup.object({
  login_id: yup.string() ,
  id: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  account_id: yup.string(),
  account_name: yup.string(),
  status: yup.string(),
  locale: yup.string(),
  timezone: yup.string(),
  email_address: yup.string(),
  mobile_phone_number: yup.string(),
  phone_number: yup.string(),
  your_reference: yup.string(),
  date_of_birth: yup.string(),
  bank_account_holder_name: yup.string(),
  beneficiary_address: yup.string(),
  beneficiary_country: yup.string(),
  bank_name: yup.string(),
  bank_address: yup.string(),
  bank_country: yup.string(),
  currency: yup.string(),
  bic_swift: yup.string(),
  iban: yup.string(),
  account_number: yup.string(),
  routing_code_type_1: yup.string(),
  routing_code_value_1: yup.string(),
  routing_code_type_2: yup.string(),
  routing_code_value_2: yup.string(),
})

